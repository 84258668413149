import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import Container from 'common/components/UI/Container';
// import Text from 'common/components/Text';
// import Image from 'common/components/Image';
import Box from 'common/components/Box';
// import Logo from 'common/components/UIElements/Logo';
import FooterWrapper from './footer.style';

// import LogoImage from 'common/assets/image/foodDelivery/logo.png';
// import facebookIcon from 'common/assets/image/foodDelivery/footer-fb.svg';
// import twitterIcon from 'common/assets/image/foodDelivery/footer-twitter.svg';
import {FormattedMessage, useIntl} from "react-intl";
import Text from "../../../common/components/Text";
// import dribbleIcon from 'common/assets/image/foodDelivery/footer-dribbble.svg';

const Footer = () => {
    const Data = useStaticQuery(graphql`
        query {
            foodDeliveryJson {
                FOOTER {
                    menu {
                        text
                        path
                        }
                    }
                }
            }
  `);

    const {menu} = Data.foodDeliveryJson.FOOTER;
    const intl = useIntl();

    return (
        <FooterWrapper>
            <Container>
                <Box className="footerMenu">
                    {menu.map((it) => {
                        return (
                            <Link target="_blank"
                                  to={intl.formatMessage({id: it.path})}>
                                <FormattedMessage id={it.text}/>
                            </Link>
                        )
                    })}
                </Box>
                {/*<Box className="socialLinks">*/}
                {/*  <Text as="span" content="Social:" />*/}
                {/*  <Link to="#">*/}
                {/*    <Image src={facebookIcon} alt="facebook" />*/}
                {/*  </Link>*/}
                {/*  <Link to="#">*/}
                {/*    <Image src={twitterIcon} alt="twitter" />*/}
                {/*  </Link>*/}
                {/*  <Link to="#">*/}
                {/*    <Image src={dribbleIcon} alt="dribbble" />*/}
                {/*  </Link>*/}
                {/*</Box>*/}
                <Box className="leftText">
                    {/*<Logo*/}
                    {/*  href="#"*/}
                    {/*  className="logo"*/}
                    {/*  logoStyle={{maxWidth: 32, maxHeight: 32}}*/}
                    {/*  logoSrc={LogoImage}*/}
                    {/*  title="FoodDelivery"*/}
                    {/*/>*/}
                    <Box className="footerText">
                        <Text
                            as="span"
                            content={`Copyright © ${new Date().getFullYear()}`}
                        />
                        <Link to="#">Getizy</Link>
                    </Box>
                </Box>
            </Container>
        </FooterWrapper>
    );
};

export default Footer;
