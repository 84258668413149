import React from "react";
import {languages} from "../../../services/intl/i18n";
import LanguageItem from "./LanguageItem";

const LanguageSwitcherHorizontal = ({onClick}) => {
    return (
        <div style={{display: "flex", justifyContent: 'space-between'}} key="languageSwitcher">
            {languages.map((it) =>
                <LanguageItem
                    key={`item-${it}`}
                    languageCode={it}
                    withLink={true}
                    highlightUsed={true}
                    onClick={onClick}
                    padding={'4px'}
                />
            )}
        </div>
    );
}

export default LanguageSwitcherHorizontal;