import React from "react";
import {Icon} from "react-icons-kit";
import {arrowRight} from 'react-icons-kit/feather/arrowRight';
import {Link} from "gatsby";

const EmailLink = (props) => {
    return <Link className="button" href={props.to}>
        {props.text}
        <Icon size={18} icon={arrowRight}/>
    </Link>
}

export default EmailLink;