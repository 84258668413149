const mainColors = {
    transparent: 'transparent', // 0
    black: '#000000', // 1
    white: '#ffffff', // 2
    headingColor: '#302b4e',
    textColor: '#43414e', // 3
    labelColor: '#767676', // 4
    inactiveField: '#f2f2f2', // 5
    inactiveButton: '#b7dbdd', // 6
    inactiveIcon: '#EBEBEB', // 7
    primary: '#055FFC', // 8
    primaryHover: '#055FFC', // 9
    secondary: '#055FFC', // 10
    secondaryHover: '#489BFF', // 11
    yellow: '#055FFC', // 12
    yellowHover: '#055FFC', // 13
    borderColor: '#055FFC', //14
    darkText: '#0F2137', //15
    grayBackground: '#F1F5F8', //16
    blackTwo: '#081C27', //17
    primaryBoxShadow: '0px 8px 20px -6px rgba(42, 162, 117, 0.57)',
    secondaryBoxShadow: '0px 8px 20px -6px rgba(237, 205, 55, 0.5)',
}

const bannerSection = {
    background: mainColors.white,
}

const colors = {
    ...mainColors,
    bannerSection: {...bannerSection}
};

export default colors;
