const languages = ['fr', 'en', 'nl', 'de'];
const defaultLanguage = 'fr';
const languageNames = {
    'fr': 'French',
    'en': 'English',
    'nl': 'Dutch',
    'de': 'German',
}

exports.defaultLanguage = defaultLanguage;
exports.languages = languages;
exports.languageNames = languageNames;