import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import Container from 'common/components/UI/Container';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Box from 'common/components/Box';
import DownloadAppWrapper from './downloadApp.style';
import {useIntl} from "react-intl";
import {useCurrentLocale} from "../../../services/intl/i18n.utils";

const DownloadApp = () => {
    const Data = useStaticQuery(graphql`
    query {
      foodDeliveryJson {
        DOWNLOAD_APP_DATA {
          sectionImageEn {
            publicURL
          }
          sectionImageFr {
            publicURL
          }
          sectionImageNl {
            publicURL
          }
          sectionImageDe {
            publicURL
          }
          title
          text
          buttons {
            icon {
              publicURL
            }
            text
            title
            link
          }
        }
      }
    }
  `);
    const {sectionImageEn, sectionImageFr, sectionImageNl, sectionImageDe, title, text, buttons} =
        Data.foodDeliveryJson.DOWNLOAD_APP_DATA;
    const intl = useIntl();
    const {forLocale} = useCurrentLocale();

    const sectionImage = forLocale(sectionImageEn, sectionImageFr, sectionImageNl, sectionImageDe);

    return (
        <DownloadAppWrapper id="download">
            <Container>
                <Box className="image">
                    <Image src={sectionImage.publicURL}
                           alt={intl.formatMessage({id: title})}/>
                </Box>
                <Box className="content">
                    <Heading as="h2" content={intl.formatMessage({id: title})}/>
                    <Text as="p" content={intl.formatMessage({id: text})}/>
                    <Box className="buttonWrap">
                        {buttons.map(({icon, text, title, link}, index) => (
                            <Link
                                className="downloadAppBtn"
                                to={link}
                                key={`download-app-button-${index}`}
                            >
                                <Image
                                    src={icon.publicURL}
                                    width="30"
                                    height="30"
                                    alt={intl.formatMessage({id: title})}
                                />
                                <Box className="btnContent">
                                    <Text as="span" content={intl.formatMessage({id: text})}/>
                                    <Text as="p" content={intl.formatMessage({id: title})}/>
                                </Box>
                            </Link>
                        ))}
                    </Box>
                </Box>
            </Container>
        </DownloadAppWrapper>
    );
};

export default DownloadApp;
