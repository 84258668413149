import React, {Fragment} from 'react';
import {ThemeProvider} from 'styled-components';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import Sticky from 'react-stickynode';
import {foodDeliveryTheme} from 'common/theme/foodDelivery';
import {ResetCSS} from 'common/assets/css/style';
import {
    GlobalStyle,
    ContentWrapper,
} from '../containers/FoodDelivery/foodDelivery.style';

import Navbar from '../containers/FoodDelivery/Navbar';
import Banner from '../containers/FoodDelivery/Banner';
import HowWorks from '../containers/FoodDelivery/HowWorks';
import ProductDelivery from '../containers/FoodDelivery/ProductDelivery';
// import QualityFeatures from '../containers/FoodDelivery/QualityFeatures';
import DownloadApp from '../containers/FoodDelivery/DownloadApp';
import Gallery from '../containers/FoodDelivery/Gallery';
import CallToAction from '../containers/FoodDelivery/CallToAction';
import Footer from '../containers/FoodDelivery/Footer';
import LocalizeProvider from "../services/intl/LocalizeProvider";
import GetizySeo from "../components/GetizySeo";

const FoodDelivery = (props) => {
    return (
        <ThemeProvider theme={foodDeliveryTheme}>
            <LocalizeProvider {...props}>
                <Fragment>
                    <GetizySeo/>
                    <ResetCSS/>
                    <GlobalStyle/>
                    <ContentWrapper>
                        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                            <DrawerProvider>
                                <Navbar/>
                            </DrawerProvider>
                        </Sticky>
                        <Banner/>
                        <HowWorks/>
                        <ProductDelivery/>
                        {/*<QualityFeatures />*/}
                        <DownloadApp/>
                        <Gallery/>
                        <CallToAction/>
                        <Footer/>
                    </ContentWrapper>
                </Fragment>
            </LocalizeProvider>
        </ThemeProvider>
    );
};
export default FoodDelivery;
