import React from "react";
import Button from "common/components/Button";
import {defaultLanguage} from "../../../services/intl/i18n";
import {useCurrentLocale} from "../../../services/intl/i18n.utils";

const LanguageItem = ({languageCode, withLink, highlightUsed, onClick, ...props}) => {
    const {getLocale} = useCurrentLocale();

    const isDefault = languageCode === defaultLanguage;
    const isAlreadyUsed = languageCode === getLocale();

    let navigationLink;
    if (!withLink || isAlreadyUsed) {
        navigationLink = undefined;
    } else if (isDefault) {
        navigationLink = "/";
    } else {
        navigationLink = `/${languageCode}`;
    }

    const colors = highlightUsed && isAlreadyUsed ? "primaryWithBg" : "secondary";
    return <div key={languageCode}>
        <Button
            colors={colors}
            onClick={onClick}
            title={languageCode.toUpperCase()}
            url={navigationLink}
            {...props}
        />
    </div>
}

export default LanguageItem;