import React, { useContext } from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import PropTypes from 'prop-types';
import NavbarWrapper from 'common/components/Navbar';
import Drawer from 'common/components/Drawer';
import Logo from 'common/components/UIElements/Logo';
import Box from 'common/components/Box';
import HamburgMenu from 'common/components/HamburgMenu';
import Container from 'common/components/UI/Container';
import { DrawerContext } from 'common/contexts/DrawerContext';

import ScrollSpyMenu from 'common/components/ScrollSpyMenu';

import LogoImage from 'common/assets/image/foodDelivery/logo-small.png';
import LogoTextual from 'common/assets/image/foodDelivery/logo-textual-cropped.png';
import colors from 'common/theme/foodDelivery/colors';
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import {createMailActionLink} from "common/utils/email-utils";
import {useIntl} from "react-intl";
import Button from "common/components/Button";

const Navbar = ({ navbarStyle, logoStyle, logoTextualStyle, logoTextualWrapper, button, row, menuWrapper }) => {
  const Data = useStaticQuery(graphql`
    query {
      foodDeliveryJson {
        MENU_ITEMS {
          label
          path
          offset
        }
        MENU_PRIMARY_BUTTON {
          label
          path
        }
        DRIVER_FORM {
          labelNavBar
          email
          emailSubject
          emailBody
        }
      }
    }
  `);
  const { state, dispatch } = useContext(DrawerContext);
  const intl = useIntl();

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  // const {label, path} = Data.foodDeliveryJson.MENU_PRIMARY_BUTTON
  const {labelNavBar, email, emailSubject, emailBody} = Data.foodDeliveryJson.DRIVER_FORM;
  const emailAction = createMailActionLink(email, intl.formatMessage({id: emailSubject}), intl.formatMessage({id: emailBody}));

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Box {...row}>
          <Logo
            href="#"
            logoSrc={LogoImage}
            title="Getizy"
            logoStyle={logoStyle}
            className="main-logo"
          />
          <div style={logoTextualWrapper} className="hideOnDesktop">
            <Logo
                href="#"
                title="Getizy"
                logoSrc={LogoTextual}
                logoStyle={logoTextualStyle}
            />
          </div>
          <Box {...menuWrapper} className="mainMenuWrapper">
            <ScrollSpyMenu
              className="main_menu"
              menuItems={Data.foodDeliveryJson.MENU_ITEMS}
              offset={-70}
              emailTitle={intl.formatMessage({id: labelNavBar})}
              emailActionLink={emailAction}
              button={button}
            />
            {/*<Link className="navbar_button" to="#">*/}
            {/*  <Button {...button} title="Login Now" />*/}
            {/*</Link>*/}
            {/*<Link className="navbar_button_two" to="#">*/}
            {/*  <Button {...button} title="Join now" />*/}
            {/*</Link>*/}
            {/*<Link className="navbar_button" to={path}>*/}
            {/*  <Button {...button} title={label} />*/}
            {/*</Link>*/}
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor={colors.primary} />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={Data.foodDeliveryJson.MENU_ITEMS}
                drawerClose={true}
                offset={-100}
                emailTitle={intl.formatMessage({id: labelNavBar})}
                emailActionLink={emailAction}
                button={button}
              />
            </Drawer>
            <div style={{marginLeft: 'auto', minWidth: '80px', display: 'flex'}} className="hideOnMobile">
              <Link className="navbar_button_no_hide" href={emailAction} >
                <Button {...button} title={intl.formatMessage({id: labelNavBar})} />
              </Link>
              <LanguageSwitcher/>
            </div>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    className: 'food_delivery_navbar',
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['126px', '126px'],
    minWidth: ['64px', '64px'],
    maxHeight: ['64px', '64px'],
    minHeight: ['32px', '32px'],
  },
  logoTextualStyle: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: ['126px', '126px'],
    minWidth: ['64px', '64px'],
    maxHeight: ['58px', '58px'],
    minHeight: ['32px', '32px'],
    objectFit: 'contain'
  },
  logoTextualWrapper: {
    marginLeft: "auto",
    marginRight: 'auto',
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'primaryWithBg',
    minHeight: 'auto',
    height: `${1}`,
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default Navbar;
