import React from "react";
import DropdownMenu from "common/components/Dropdown";
import LanguageItem from "./LanguageItem";
import {languages} from "../../../services/intl/i18n";
import {useCurrentLocale} from "../../../services/intl/i18n.utils";

const LanguageSwitcher = () => {
    const {getLocale} = useCurrentLocale();

    return <DropdownMenu
        dropdownWrapperStyle={{display: 'flex', justifyContent: 'center'}}
        dropdownDirection="auto"
        content={<LanguageItem languageCode={getLocale()} withLink={false}/>}
        dropdownItems={languages.map((it) => <LanguageItem languageCode={it} withLink={true}/>)}
    />
}

export default LanguageSwitcher;