import {defaultLanguage, languages} from "./i18n";
import {useIntl} from "react-intl";

const getTranslatedPath = (pathname, to) => {
    const currentPageLanguage = getCurrentPageLanguage(pathname);

    let languagePath = '';
    const isDefaultLanguage = defaultLanguage === currentPageLanguage;
    if (!isDefaultLanguage) {
        languagePath = '/' + currentPageLanguage
    }


    let outputPath = `${languagePath}${to}`;

    const hasTrailingSlash = outputPath.endsWith("/");
    if (!hasTrailingSlash) {
        outputPath += "/";
    }

    return outputPath;
}

const useCurrentLocale = () => {
    const intl = useIntl();
    const getLocale = () => {
        return intl.locale;
    }
    const forLocale = (en, fr, nl, de) => {
        switch (getLocale()) {
            case "en":
                return en;
            case "fr":
                return fr;
            case "nl":
                return nl;
            case "de":
                return de;
        }
    }
    return {getLocale, forLocale};
}

const getCurrentPageLanguage = (pathname) => {
    const pathElements = pathname.split("/");
    for (let element of pathElements) {
        for (let language of languages) {
            if (element === language) {
                return language;
            }
        }
    }
    return defaultLanguage;
};

export {getCurrentPageLanguage, getTranslatedPath, useCurrentLocale}