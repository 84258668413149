import React from 'react';
import Fade from 'react-reveal/Fade';
import {useStaticQuery, graphql, Link} from 'gatsby';
import {Icon} from 'react-icons-kit';
import {arrowRight} from 'react-icons-kit/feather/arrowRight';
import Container from 'common/components/UI/Container';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Box from 'common/components/Box';
import HowWorksWrapper from './howWorks.style';
import {FormattedMessage, useIntl} from "react-intl";
import TextWithEmail from "common/components/Email/TextWithEmail";
import {createMailActionLink} from "common/utils/email-utils";
import EmailLink from "common/components/Email/EmailLink";

const HowWorks = () => {
    const Data = useStaticQuery(graphql`
    query {
      foodDeliveryJson {
        HOW_WORKS_DATA {
          title
          posts {
            id
            icon {
              publicURL
            }
            title
            text
            link {
              path
              label
            }
          }
        }
        DRIVER_FORM {
          label
          email
          emailSubject
          emailBody
        }
      }
    }
  `);
    const {title, posts} = Data.foodDeliveryJson.HOW_WORKS_DATA;
    const {label, email, emailSubject, emailBody} = Data.foodDeliveryJson.DRIVER_FORM;
    const intl = useIntl();

    const emailAction = createMailActionLink(email, intl.formatMessage({id: emailSubject}), intl.formatMessage({id: emailBody}));

    return (
        <HowWorksWrapper id="how_works">
            <Container>
                <Heading as="h2" content={intl.formatMessage({id: title})}/>
                <Box className="postWrap">
                    {posts.map(({id, icon, title, text, link}, index) => (
                        <Box className="post" key={`how-works-post-key-${index}`}>
                            <Fade bottom cascade>
                                <Box className="imageBox" clone>
                                    <Image src={icon.publicURL} alt={intl.formatMessage({id: title})}/>
                                </Box>
                                <Heading as="h3" content={intl.formatMessage({id: title})}/>
                                <Text as="p" content={intl.formatMessage({id: text})}/>
                                <>
                                    {id === 'join_driver' && <TextWithEmail text={intl.formatMessage({id: label})} email={email}/>}
                                    {id === 'join_driver' && <EmailLink text={intl.formatMessage({id: link.label})} to={emailAction}/>}
                                    {id === 'join_user' && <Link className="button" to={link.path}><FormattedMessage id={link.label}/> <Icon size={18} icon={arrowRight}/></Link>}
                                </>
                            </Fade>
                        </Box>
                    ))}
                </Box>
            </Container>
        </HowWorksWrapper>
    );
};

export default HowWorks;
